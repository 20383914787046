import { Box, Flex, Text } from "@theme-ui/components"
import { Link, graphql, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useContext, useEffect } from "react"

import AssetModule from "../components/asset-module"
import Layout from "../components/layout"
import PeopleModule from "../components/people-module"
import Player from "../components/player"
import SEO from "../components/seo"
import TextModule from "../components/text-module"

import { InterfaceContext } from "../context/interface"

import Footer from "../components/footer"

const PageTemplate = ({ data, pageContext, location }) => {
  let { footerNavigation, caseContent } = data

  const { setTranslationLinks } = useContext(InterfaceContext)

  useEffect(() => {
    setTranslationLinks(pageContext.canonicals)
  }, [])
  const handleRenderCaseContent = () => {
    if (caseContent.vimeoId) {
      return (
        <>
          <Box
            sx={{
              display: ["block", "none"],
            }}
          >
            <Player
              showPlay
              id={caseContent.slug}
              thumbnail={caseContent.thumbnail}
              aspectRatio={"3:2"}
              vimeoId={caseContent.vimeoId}
            />
          </Box>
          <Box
            sx={{
              display: ["none", "block"],
            }}
          >
            <Player
              showPlay
              id={caseContent.slug}
              thumbnail={caseContent.thumbnail}
              aspectRatio={"2.39:1"}
              vimeoId={caseContent.vimeoId}
            />
          </Box>
        </>
      )
    }

    if (caseContent.bannerStills) {
      return (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gridColumnGap: "10px",
          }}
        >
          {caseContent.bannerStills.map((s) => (
            <GatsbyImage
              image={s.gatsbyImageData}
              key={s.id}
              height="100%"
              flex="1"
              alt=""
            />
          ))}
        </Box>
      )
    }

    if (caseContent.mainContent) {
      return (
        <Box
          sx={{
            aspectRatio: ["3/2", "unset"],
          }}
        >
          <img
            style={{
              height: "100%",
              objectFit: "cover",
              display: "block",
            }}
            src={caseContent?.mainContent?.file.url}
            loading="lazy"
            alt=""
          />
        </Box>
      )
    }
    return null
  }
  const [lang] = pageContext.locale.split("-")
  let prefix = "/"
  if (lang !== "en") {
    prefix = `/${lang}/`
  }
  const now = new Date()
  return (
    <Layout
      prefix={prefix}
      dropup={data.dropup}
      subNav={data.subnav}
      shouldShowMobileNav={true}
      location={location}
      nav={data.navigation}
      locale={pageContext.locale}
    >
      <SEO
        locale={pageContext.locale}
        canonicals={pageContext.canonicals}
        title={`${caseContent.title} - ${caseContent.clientName}`}
        description={
          caseContent.description &&
          caseContent.description.childMarkdownRemark.html
        }
      />
      <Flex sx={{ flexDirection: "column" }}>
        <Box sx={{ width: "100%" }}>{handleRenderCaseContent()}</Box>
        <Flex
          m="auto"
          px="40px"
          pt={["50px", "100px"]}
          pb={["38px", "100px"]}
          sx={{
            width: "100%",
            flexDirection: "column",
            justifyContent: "space-between",
            maxWidth: "1200px",
          }}
        >
          <Flex>
            <Box sx={{ width: "100%", maxWidth: "500px" }}>
              <Box mb={"13px"}>
                <Box
                  mb="5px"
                  sx={{
                    fontFamily: "pragmatica-extended, sans-serif",
                    fontWeight: "200",
                    fontSize: "12px",
                    lineHeight: "13px",
                    textTransform: "uppercase",
                  }}
                >
                  Client
                </Box>
                <Box
                  sx={{
                    fontFamily: "pragmatica-extended, sans-serif",
                    fontSize: "12px",
                    lineHeight: "13px",
                    fontWeight: "400",
                  }}
                >
                  {caseContent.clientName}
                </Box>
              </Box>
              <Box mb={23}>
                <Box
                  mb="5px"
                  sx={{
                    fontFamily: "pragmatica-extended, sans-serif",
                    fontWeight: "200",
                    fontSize: "12px",
                    lineHeight: "13px",
                    textTransform: "uppercase",
                  }}
                >
                  Case
                </Box>
                <Box
                  sx={{
                    fontFamily: "pragmatica-extended, sans-serif",
                    h1: {
                      fontWeight: "400",
                      fontSize: "12px",
                      my: "0",
                      lineHeight: "13px",
                    },
                  }}
                >
                  <h1>{caseContent.title}</h1>
                </Box>
              </Box>
              <Box
                sx={{
                  fontSize: "12px",
                  whiteSpace: "pre-wrap",
                }}
              ></Box>
            </Box>
            <Box sx={{ width: "100%", height: "100%" }}>
              <Flex>
                <Box
                  sx={{
                    flex: 1,
                    marginLeft: "2px",
                    height: "3px",
                    backgroundColor: caseContent.color1,
                  }}
                />
                <Box
                  sx={{
                    flex: 1,
                    marginLeft: "2px",
                    height: "3px",
                    backgroundColor: caseContent.color2,
                  }}
                />
                <Box
                  sx={{
                    flex: 1,
                    marginLeft: "2px",
                    height: "3px",
                    backgroundColor: caseContent.color3,
                  }}
                />
              </Flex>
            </Box>
          </Flex>
          <Flex>
            <Box sx={{ fontFamily: "Formular" }}>
              {caseContent.description && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: caseContent.description.childMarkdownRemark.html,
                  }}
                />
              )}
            </Box>
          </Flex>
        </Flex>
        <Box
          sx={{
            ".peo-mod + .peo-mod": {
              paddingTop: 0,
            },
          }}
        >
          {(caseContent.modules || []).map((module, index) => {
            if (!module?.internal?.type) {
              return null
            }

            if (module.internal.type === "ContentfulSpacer") {
              const nextModuleIsTextModule = caseContent.modules[index + 1] && caseContent.modules[index + 1].internal.type === "ContentfulTextModule"
              const previousModuleIsTextModule = caseContent.modules[index - 1] && caseContent.modules[index - 1].internal.type === "ContentfulTextModule"
              const val = module.spacing || 50

              if (nextModuleIsTextModule || previousModuleIsTextModule) return (
                <Box key={module.id} pb={[0, val]} />
              ) // remove mobile spacers around text modules to keep margin consistent. The spacers were previously used because there was no padding on the text modules.
              return <Box key={module.id} pb={[val / 2.5, val]} />
            }

            if (module.internal.type === "ContentfulTextModule") {
              module.columns?.forEach((col) => (col.enlarge = false))
              return <TextModule pt={["50px", "16px"]} pb={["38px", "16px"]} px="40px" key={module.id} data={module} />
            }
            if (module.internal.type === "ContentfulAssetModule") {
              return <AssetModule key={module.id} data={module} />
            }
            if (module.internal.type === "ContentfulPeopleModule") {
              return (
                <PeopleModule
                  padTop={true}
                  padBottom={true}
                  key={module.id}
                  data={module}
                  m="auto"
                  px="40px"
                  pt={["50px", "100px"]}
                  pb={["38px", "100px"]}
                />
              )
            }

            return null
          })}
        </Box>
        <Footer
          nav={footerNavigation}
          prefix={prefix}
          backLink={"../../featured-cases"}
        />
      </Flex>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query CaseContent($slug: String!, $locale: String!) {
    subnav: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Footer" }
    ) {
      ...navigationFields
    }
    dropup: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Services" }
    ) {
      ...navigationFields
    }
    navigation: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Main" }
    ) {
      ...navigationFields
    }
    footerNavigation: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Footer" }
    ) {
      ...footerNavigationFields
    }
    caseContent: contentfulCase(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      ...casePreviewFields
      description {
        description
        childMarkdownRemark {
          html
        }
      }
      modules {
        ... on ContentfulAssetModule {
          id
          vimeoId
          vimeoThumbnail {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            file {
              url
            }
          }
          cols: columns
          assets {
            file {
              contentType
              url
              fileName
            }
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
          internal {
            type
          }
        }
        ... on ContentfulTextModule {
          id
          columns {
            ... on ContentfulTextColumn {
              __typename
              id
              enlarge
              center
              text {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
          mobileGap
          desktopGap
          internal {
            type
          }
        }
        ... on ContentfulSpacer {
          __typename
          id
          spacing
          internal {
            type
          }
        }
        ... on ContentfulPeopleModule {
          id
          roles {
            ... on ContentfulRole {
              roleName
              people {
                name
              }
              internal {
                type
              }
            }
          }
          internal {
            type
          }
        }
      }
    }
  }
`
